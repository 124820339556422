<template>
  <div id="buy_list">
    <div id="panel-header">
      <span class="top-title">采购列表</span>
      <Button type="primary" class="client_create" @click="create('clientFormItem')" v-if="aCheck.rightCheck('edit_procurement_list')">
        添加采购
      </Button>
    </div>
    <!--列表筛选条件-->
    <div id="filter-bar">
      <Card dis-hover>
        <Form ref="list_search_item" :model="formItem" inline>
          <FormItem prop="buy_no">
            <Input @on-blur="searchSubmit" v-model="formItem.buy_no" placeholder="请填写采购单号" class="w-200"/>
          </FormItem>

          <FormItem prop="client_id">
            <Select @on-change="searchSubmit" v-model="formItem.client_id" placeholder="请选择供应商" filterable class="w-200">
              <Option v-for="item in supplierList" :value="item.id" :key="item.id">{{ item.client_name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="status">
            <Select @on-change="searchSubmit" v-model="formItem.status" placeholder="请选择采购状态" class="w-200">
              <Option v-for="item in allBuyStatus" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="pay_status">
            <Select @on-change="searchSubmit()" v-model="formItem.pay_status" multiple  placeholder="请选择付款状态" filterable class="w-200">
              <Option v-for="item in allBuyPayStatus" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="buy_date">
            <DatePicker type="daterange" @on-change="timeChange" placement="bottom-end"
                        placeholder="请选择采购时间" class="w-200" v-model="formItem.buy_date"></DatePicker>
          </FormItem>

          <FormItem prop="include_tax">
            <Select @on-change="searchSubmit()" v-model="formItem.include_tax"  placeholder="请选择是否含税" filterable class="w-200">
              <Option v-for="item in taxList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem>&nbsp;
            <Button type="text" class="main-font-color" @click="cancelSearch('list_search_item')">
              清除
            </Button>
          </FormItem>

        </Form>
      </Card>
    </div>

    <!--列表-->
    <div id="list" class="buy_list">
      <ColumnsSet ref="columns_set" :list_more="list_more"/>
      <Table :loading="common.list_loading" :columns="list_more.columns" :data="listInfo">
        <template slot-scope="{ row }" slot="buy_no">
          <strong @click="checkOrderInfo(row)" class="pointer">
            {{ row.buy_no }}{{ row.part_no_postfix ? ('-' + row.part_no_postfix) : '' }}
          </strong>
        </template>
        <template slot-scope="{ row }" slot="abstract">
          <span class="fs-12"><span class="money-color fw-bold" v-show="row.include_tax == 1">[票]</span>{{ row.abstract }}</span>
        </template>
        <template slot-scope="{ row }" slot="status">
          <Tag :style="getStatusColorTg(row.status)" class="white-font-color">{{ getStatusText(row.status) }}</Tag>
        </template>
        <template slot-scope="{ row }" slot="pay_status">
          <span class="pointer" :style="getPayStatusColor(row.pay_status)" @click="setPayStatusFinish(row, 'list')">{{ getPayStatusText(row.pay_status) }}</span>
        </template>

        <template slot-scope="{ row }" slot="buy_date">
          <span>{{ row.buy_date.substring(0, 10) }}</span>
        </template>

        <template slot-scope="{ row, index }" slot="action">
          <!--待审核情况下显示-->
          <Button v-show="row.status==1" type="text" size="small" :style="getStatusColor(row.status)"
                  @click="checkOrderInfo(row)" v-if="aCheck.rightCheck('valid_procurement_list')">
            <Icon type="ios-checkmark-circle"/>
            提交
          </Button>

          <Button v-show="[4,5,6,8].includes(row.status)" type="text" size="small"
                  :style="row.status==5 ? 'color:#c5c8ce' : 'color:#19be6b'" @click="checkOrderInfo(row)">
            <Icon type="ios-checkmark-circle"/>
            查看
          </Button>

          <Dropdown v-show="[1,2,3].includes(row.status)">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem v-show="row.status==1" v-if="aCheck.rightCheck('edit_procurement_list')">
                <span @click="listEdit(row)" class="f12">修改订单</span>
              </DropdownItem>
              <DropdownItem v-show="row.status==1" v-if="aCheck.rightCheck('edit_procurement_list')">
                <span @click="listDeleteAlert(row)" class="f12">删除订单</span></DropdownItem>
              <DropdownItem v-show="row.status == 2" v-if="aCheck.rightCheck('operate_procurement_list')">
                <span @click="cancelBuyOrder(row)" class="f12">取消订单</span>
              </DropdownItem>

              <DropdownItem v-show="row.status==4" v-if="aCheck.rightCheck('edit_procurement_list')">
                <span @click="listEdit(row)" class="f12">修改订单</span>
              </DropdownItem>
              <DropdownItem v-show="row.status==4" v-if="aCheck.rightCheck('edit_procurement_list')">
                <span @click="listDeleteAlert(row)" class="f12">删除订单</span></DropdownItem>

            </DropdownMenu>
          </Dropdown>
          <Dropdown v-show="[4].includes(row.status)" v-if="aCheck.rightCheck('force_procurement_list')">
            <Button size="small" type="text">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem>
                <span @click="forceDelete(row)" class="f12">强制删除</span>
              </DropdownItem>
              <DropdownItem>
                <span @click="forceEdit(row)" class="f12">强制修改</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </template>
      </Table>
      <div class="page">
        <Page :total="list.total" :page-size="list.size" show-total show-elevator @on-change="changePage"
              :current="list.page" show-sizer @on-page-size-change="pageSizeChange" :page-size-opts="[10, 15, 20, 30]"/>
      </div>
    </div>

    <!--添加修改采购订单-->
    <Modal v-model="modal1" :title="model_title" width="1280">
      <div class="modalForm">
        <Form v-show="!common.view_loading" ref="clientFormItem" :model="clientFormItem" :label-width="100" :rules="ruleInline" label-colon>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="采购单号" prop="buy_no">
                <Input v-model="clientFormItem.buy_no" disabled placeholder="请填写采购单号" style="width: 130px;"></Input>
                &nbsp;<span>-</span>&nbsp;
                <Input v-model="clientFormItem.part_no_postfix" placeholder="请填写自定义编号"
                       style="width: 156px;" maxlength="20"></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="供应商" prop="supplier_id">
                <Select v-model="clientFormItem.supplier_id" filterable placeholder="选择供应商" style="width: 280px;">
                  <Option v-for="item in supplierList" :value="item.id">{{ item.client_name }}</Option>
                </Select>
                <Icon type="md-add" class="ml-10 main-font-color fw-bold pointer" @click="addClients"/>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="采购日期" prop="buy_date">
                <DatePicker :value="clientFormItem.buy_date" type="datetime"
                            format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请填写采购时间(不填，默认当前时间)" style="width: 309px"
                            @on-change="getDateTime"></DatePicker>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="物流" prop="logistics_id">
                <Select v-model="clientFormItem.logistics_id" placeholder="请选择物流">
                  <Option value="0">暂无物流</Option>
                  <Option v-for="item in logisticsList" :value="item.id">{{ item.client_name }}
                    ({{ item.contact_person }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流单号" prop="logistics_order_id">
                <Input v-model="clientFormItem.logistics_order_id" placeholder="请填写物流单号"
                       maxlength="300"></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流运费" prop="logistics_pay">
                <Input v-model="clientFormItem.logistics_pay" placeholder="请填写物流运费" type="number"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="含税" prop="include_tax">
                <Select v-model="clientFormItem.include_tax" @on-change="setBuyGoodsColumn" placeholder="请选择是否含税">
                  <Option v-for="item in taxList" :value="item.id">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Col>
<!--            <Col span="8">-->
<!--              <FormItem label="收票状态" prop="invoicing_status">-->
<!--                <Select v-model="clientFormItem.invoicing_status" placeholder="请选择收票状态">-->
<!--                  <Option v-for="item in invoicingStatusList" :value="item.id">{{ item.name }}</Option>-->
<!--                </Select>-->
<!--              </FormItem>-->
<!--            </Col>-->
            <Col span="8">
              <FormItem label="备注" prop="remark">
                <Input v-model="clientFormItem.remark" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="凭证" prop="certificate">
                <!-- fileList为回传属性    uploadParam用来传值                -->
                <ImageUpload ref="image_upload" @fileList="getFileList" :uploadParam="clientFormItem.uploadParam"
                             :key="clientFormItem.uploadParam.key"/>
              </FormItem>
            </Col>
          </Row>
          <Row class="mt2 display-block">
            <Card dis-hover>
              <p slot="title">采购明细</p>
<!--              <Button type="warning" slot="extra"  size="small" icon="md-arrow-down" class="f12" @click="importProduct">-->
<!--                商品导入-->
<!--              </Button>-->
              <Button type="primary" slot="extra" size="small" @click="addGoods()" class="f12 ml-5">
                <Icon type="md-add"/>
                添加商品
              </Button>
              <Table :columns="table_columns_modal" :data="clientFormItem.goods_setted" show-summary
                     :summary-method="addGoodsHandleSummary">
                <template slot-scope="{ row, index }" slot="part_name">
                  <span class="money-color fw-bold fs-12" v-show="row.free_flag==1">[赠]</span>
                  <span>{{row.part_name}}</span>
                </template>
                <template slot-scope="{ row, index }" slot="wholesale_price">
                  <Input type="number" v-model="row.wholesale_price" @on-change="priceSum(row, index)"/>
                </template>

                <template slot-scope="{ row, index }" slot="selling_price">
                  <Input type="number" v-model="row.selling_price" @on-change="priceSum(row, index)"/>
                </template>

                <template slot-scope="{ row, index }" slot="buy_num">
                    <Input type="number" v-model="row.buy_num" @on-change="priceSum(row, index)"/>
                </template>

                <template slot-scope="{ row, index }" slot="buy_price">
                    <Input type="number" v-model="row.buy_price" @on-change="priceSum(row,index)"/>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <Button type="text" size="small" class="fs-12" @click="deleteGoodsSelected(row, index)">
                    <Icon type="ios-trash"/>
                    删除
                  </Button>
                  <Button v-show="row.free_flag==0" class="fs12" type="text" size="small" @click="freeGoodsSelected(row, index)">
                    <Icon type="ios-pizza"/>
                    赠品
                  </Button>
                  <Button v-show="row.free_flag==1" class="fs12" type="text" size="small" @click="chargeGoodsSelected(row, index)">
                    <Icon type="ios-pizza"/>
                    取消
                  </Button>
                </template>
              </Table>
            </Card>
          </Row>

          <Row :gutter="20" class="mt-10">
            <Col span="4">
              <FormItem label="优惠" prop="discount_amount">
                <Input v-model="clientFormItem.discount_amount" placeholder="请填写优惠" type="number"></Input>
              </FormItem>
            </Col>
            <Col span="3">
              <FormItem label="物流运费" prop="logistics_pay" class="mb-0">
                <span>{{ clientFormItem.logistics_pay }}</span>
              </FormItem>
            </Col>
            <Col span="3">
              <FormItem label="商品金额" prop="goods_pay" class="mb-0">
                <span>{{common.summary}}</span>
              </FormItem>
            </Col>
            <Col span="3">
              <FormItem label="采购总额" prop="goods_pay" class="mb-0">
                <strong class="main-font-color">{{getCreateResultMoney(common.summary, clientFormItem.discount_amount, clientFormItem.logistics_pay)}}</strong>
              </FormItem>
            </Col>
            <Col span="5">
              <FormItem label="实付金额" prop="real_pay" class="mb-0">
                <div style="display: flex;align-items: center;">
                  <Input v-model="clientFormItem.real_pay" placeholder="请填写实付金额" type="number"></Input>
                  <Icon class="main-font-color ml-5 pointer" type="md-refresh" @click="setRealPay"/>
                </div>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="付款说明" prop="pay_desc">
                <Input v-model="clientFormItem.pay_desc" placeholder="请填写付款说明"></Input>
                <div v-for="item in common.pay_desc_list" @click="setPayDesc(clientFormItem, item.name)" class="ivu-tag ivu-tag-size-default ivu-tag-default ivu-tag-checked pointer">
                  <span class="c-515a6e">{{ item.name }}</span>
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>
        <Spin fix v-show="common.view_loading" size="large"></Spin>
      </div>
      <div slot="footer">
        <Button @click="handleReset(clientFormItem)">关闭</Button>
        <Button type="primary" :loading="modal_loading.add_edit" @click="createBuyOrder('clientFormItem')">保存
        </Button>
      </div>
    </Modal>

    <!--添加修改采购订单-->
    <Modal v-model="force_edit.modal" title="强制修改订单信息" width="1280">
      <div class="modalForm">
        <Form v-show="!common.view_loading" ref="clientFormItem" :model="clientFormItem" :label-width="100" :rules="ruleInline" label-colon>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="采购单号" prop="buy_no">
                <Input v-model="clientFormItem.buy_no" disabled placeholder="请填写采购单号" style="width: 130px;"></Input>
                &nbsp;<span>-</span>&nbsp;
                <Input v-model="clientFormItem.part_no_postfix"  disabled placeholder="请填写自定义编号"
                       style="width: 156px;" maxlength="20"></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="供应商" prop="supplier_id">
                <Select disabled v-model="clientFormItem.supplier_id" filterable placeholder="选择供应商" style="width: 280px;">
                  <Option v-for="item in supplierList" :value="item.id">{{ item.client_name }}</Option>
                </Select>
                <Icon type="md-add" class="ml-10 main-font-color fw-bold pointer" @click="addClients"/>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="采购日期" prop="buy_date">
                <DatePicker disabled :value="clientFormItem.buy_date" type="datetime"
                            format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请填写采购时间(不填，默认当前时间)" style="width: 309px"
                            @on-change="getDateTime"></DatePicker>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="物流" prop="logistics_id">
                <Select v-model="clientFormItem.logistics_id" placeholder="请选择物流">
                  <Option value="0">暂无物流</Option>
                  <Option v-for="item in logisticsList" :value="item.id">{{ item.client_name }}
                    ({{ item.contact_person }})
                  </Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流单号" prop="logistics_order_id">
                <Input v-model="clientFormItem.logistics_order_id" placeholder="请填写物流单号"
                       maxlength="300"></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流运费" prop="logistics_pay">
                <Input disabled v-model="clientFormItem.logistics_pay" placeholder="请填写物流运费" type="number"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="含税" prop="include_tax">
                <Select v-model="clientFormItem.include_tax" @on-change="setBuyGoodsColumn" placeholder="请选择是否含税">
                  <Option v-for="item in taxList" :value="item.id">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="备注" prop="remark">
                <Input v-model="clientFormItem.remark" placeholder="请填写备注"></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="凭证" prop="certificate">
                <!-- fileList为回传属性    uploadParam用来传值                -->
                <ImageUpload ref="image_upload" @fileList="getFileList" :uploadParam="clientFormItem.uploadParam"
                             :key="clientFormItem.uploadParam.key"/>
              </FormItem>
            </Col>
          </Row>
          <Row class="mt2 display-block">
            <Card dis-hover>
              <p slot="title">采购明细</p>
              <Table :columns="table_columns_modal" :data="clientFormItem.goods_setted" show-summary
                     :summary-method="addGoodsHandleSummary">
                <template slot-scope="{ row, index }" slot="part_name">
                  <span class="money-color fw-bold fs-12" v-show="row.free_flag==1">[赠]</span>
                  <span>{{row.part_name}}</span>
                </template>
                <template slot-scope="{ row, index }" slot="wholesale_price">
                  <Input type="number" v-model="row.wholesale_price" @on-change="priceSum(row, index)"/>
                </template>

                <template slot-scope="{ row, index }" slot="selling_price">
                  <Input type="number" v-model="row.selling_price" @on-change="priceSum(row, index)"/>
                </template>

                <template slot-scope="{ row, index }" slot="buy_num">
                  <Input disabled type="number" v-model="row.buy_num" @on-change="priceSum(row, index)"/>
                </template>

                <template slot-scope="{ row, index }" slot="buy_price">
                  <Input disabled type="number" v-model="row.buy_price" @on-change="priceSum(row,index)"/>
                </template>
                <template slot-scope="{ row, index }" slot="action">
                  <span class="disabled-color">------</span>
                </template>
              </Table>
            </Card>
          </Row>

          <Row :gutter="20" class="mt-10">
            <Col span="4">
              <FormItem label="优惠" prop="discount_amount">
                <Input disabled v-model="clientFormItem.discount_amount" placeholder="请填写优惠" type="number"></Input>
              </FormItem>
            </Col>
            <Col span="3">
              <FormItem label="物流运费" prop="logistics_pay" class="mb-0">
                <span>{{ clientFormItem.logistics_pay }}</span>
              </FormItem>
            </Col>
            <Col span="3">
              <FormItem label="商品金额" prop="goods_pay" class="mb-0">
                <span>{{common.summary}}</span>
              </FormItem>
            </Col>
            <Col span="3">
              <FormItem label="采购总额" prop="goods_pay" class="mb-0">
                <strong class="main-font-color">{{getCreateResultMoney(common.summary, clientFormItem.discount_amount, clientFormItem.logistics_pay)}}</strong>
              </FormItem>
            </Col>
            <Col span="5">
              <FormItem label="实付金额" prop="real_pay" class="mb-0">
                <div style="display: flex;align-items: center;">
                  <Input disabled v-model="clientFormItem.real_pay" placeholder="请填写实付金额" type="number"></Input>
                </div>
              </FormItem>
            </Col>
            <Col span="6">
            </Col>
          </Row>
        </Form>
        <Spin fix v-show="common.view_loading" size="large"></Spin>
      </div>
      <div slot="footer">
        <Button @click="handleReset(clientFormItem)">关闭</Button>
        <Button type="primary" :loading="modal_loading.add_edit" @click="forceEditBuyOrder('clientFormItem')">保存
        </Button>
      </div>
    </Modal>

    <!--审核-->
    <Modal v-model="checkOrderModal" :title="checkOrderModalTitle" width="1280">
      <div class="modalForm">
        <Form v-show="!common.view_loading" :model="clientFormItem" :label-width="100" label-colon>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="采购单号" prop="buy_no">
                <span>{{ clientFormItem.buy_no }}{{clientFormItem.part_no_postfix ? ('-' + clientFormItem.part_no_postfix) : '' }}</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="供应商" prop="supplier_id">
                <span v-for="item in supplierList" v-show="item.id==clientFormItem.supplier_id">{{ item.client_name }}</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="采购时间" prop="buy_date">
                <span>{{ clientFormItem.buy_date }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="物流" prop="logistics_id">
                <span v-for="item in logisticsList" v-show="item.id==clientFormItem.logistics_id">{{ item.client_name }} ({{item.contact_person }})</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流单号" prop="logistics_order_id">
                <span>{{ clientFormItem.logistics_order_id }}</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="物流运费" prop="logistics_pay">
                <span>{{ clientFormItem.logistics_pay }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="含税" prop="include_tax">
                <span v-if="clientFormItem.include_tax == 1" class="money-color">是</span>
                <span v-else>否</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="收票状态" prop="invoicing_status">
                <span v-if="clientFormItem.invoicing_status == 1" class="main-font-color">已收票</span>
                <span v-if="clientFormItem.invoicing_status == 2" class="danger-color">待收票</span>
                <span v-if="clientFormItem.invoicing_status == 0">暂无</span>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="备注" prop="remark">
                <span>{{ clientFormItem.remark }}</span>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="10">
            <Col span="8">
              <FormItem label="凭证" prop="certificate">
                <div class="pointer image-upload-list" v-if="clientFormItem.certificate" v-for="(item, key)  in clientFormItem.certificate">
                  <img :src="item.url" alt="" @click="imgShow(key, clientFormItem.certificate)" style="width:100%;height:100%">
                </div>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="单据状态" prop="status">
                <Tag :style="getStatusColorTg(clientFormItem.status)" class="white-font-color">{{ getStatusText(clientFormItem.status) }}</Tag>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="付款状态" prop="status">
                <span :style="getPayStatusColor(clientFormItem.pay_status)">{{ getPayStatusText(clientFormItem.pay_status) }}</span>
                <Button type="text" @click="setPayStatusFinish(clientFormItem)" class="status-main fs-12" v-if="clientFormItem.pay_status == 2 && ![1,5].includes(clientFormItem.status)">
                  <Icon type="ios-checkmark-circle"/>
                  设为完结状态
                </Button>
              </FormItem>
            </Col>
          </Row>
          <Row class="display-block">
            <Card dis-hover >
              <div>
                <span style="margin:0 10px" :class="common.view_flag === 1 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(1)">采购商品</span>
                <span style="margin:0 10px" :class="common.view_flag === 3 ? 'select-flag main-font-color pointer': 'pointer'" @click="selectTap(3)">实付金额记录</span>
                <Divider style="margin: 14px 0px;"/>
              </div>
              <div v-show="common.view_flag == 1">
                <Table :columns="table_columns_modal" :data="clientFormItem.goods_setted" show-summary
                       :summary-method="addGoodsHandleSummary">
                  <template slot-scope="{ row, index }" slot="part_name">
                    <span class="money-color fw-bold fs-12" v-show="row.free_flag==1">[赠]</span>
                    <span>{{row.part_name}}</span>
                  </template>
                  <template slot-scope="{ row, index }" slot="wholesale_price">
                    <span>{{ row.wholesale_price }}</span>
                  </template>

                  <template slot-scope="{ row, index }" slot="selling_price">
                    <span>{{ row.selling_price }}</span>
                  </template>

                  <template slot-scope="{ row, index }" slot="buy_num">
                      <span>{{ row.buy_num }}</span>
                  </template>
                  <template slot-scope="{ row, index }" slot="buy_price">
                      <span>{{ row.buy_price }}</span>
                  </template>

                  <template slot-scope="{ row, index }" slot="action">
                    <span class="disabled-color fs-12">------</span>
                  </template>
                </Table>
              </div>
              <div v-show="common.view_flag == 3">
                <Table :columns="common.real_pay_columns" :data="clientFormItem.flow_info">
                  <template slot-scope="{ row, index }" slot="money">
                    <strong>{{ row.money }}</strong>
                  </template>
                </Table>
              </div>
            </Card>
          </Row>

          <div class="" style="display:flex;justify-content: space-around;">
            <FormItem label="优惠" prop="discount_amount" class="mb-0">
              <span>{{ clientFormItem.discount_amount }}</span>
            </FormItem>
            <FormItem label="物流运费" prop="logistics_pay" class="mb-0">
              <span>{{ clientFormItem.logistics_pay }}</span>
            </FormItem>
<!--            <FormItem label="采购金额" prop="summary" class="mb-0">-->
<!--              <strong class="main-font-color">{{common.summary}}</strong>-->
<!--            </FormItem>-->
            <FormItem label="采购总额" prop="goods_pay" class="mb-0">
              <strong class="main-font-color">{{ clientFormItem.goods_pay }}</strong>
            </FormItem>

            <FormItem label="本单实付" prop="goods_pay" class="mb-0">
              <strong class="main-font-color">{{ clientFormItem.real_pay }}</strong>
<!--              <span v-show="clientFormItem.status == 1 && clientFormItem.pay_desc" class="fs-12 ml-5">({{clientFormItem.pay_desc}})</span>-->
            </FormItem>

            <FormItem label="本单欠款" prop="to_pay" class="mb-0" >
              <strong class="money-color">{{clientFormItem.to_pay}}</strong>
            </FormItem>
          </div>
        </Form>
        <Spin fix v-show="common.view_loading" size="large"></Spin>
      </div>
      <div slot="footer">
        <Button @click="handleResetOrderModal(clientFormItem)">关闭</Button>
        <Button type="primary" v-show="clientFormItem.status==1" @click="showEditBuyOrder(clientFormItem)" v-if="aCheck.rightCheck('edit_procurement_list')">
          修改订单
        </Button>

        <a target="_blank" style="margin:0 8px;" v-show="clientFormItem.status==4" :href="'/BuyPrint?printType=buy&no='+clientFormItem.id" v-if="aCheck.rightCheck('operate_procurement_list')">
          <Button type="primary">打印预览</Button>
        </a>

        <Button type="info" v-show="clientFormItem.invoicing_status==2 && clientFormItem.status!=1" :loading="modal_loading.success" v-if="aCheck.rightCheck('operate_procurement_list')"
                @click="setInvoicingStatus(clientFormItem)">收票
        </Button>

        <Button type="success" v-show="clientFormItem.status==1" :loading="modal_loading.success" v-if="aCheck.rightCheck('operate_procurement_list')"
                @click="passBuyOrder(clientFormItem)">提交
        </Button>
        <Button type="warning" v-show="[0,1,2].includes(clientFormItem.pay_status) && clientFormItem.status!=1" @click="payBuyOrder(clientFormItem)" v-if="aCheck.rightCheck('operate_procurement_list')">
          付款
        </Button>
      </div>
    </Modal>

    <!--采购付款-->
    <Modal v-model="pay_buy.modal" title="付款操作" width="800">
      <div class="modalForm">
        <Form ref="pay_buy_item" :model="pay_buy.item" :label-width="100" label-colon>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="订单金额" prop="goods_pay">
                <strong>{{pay_buy.item.goods_pay}}</strong>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="已付金额" prop="goods_pay">
                <span class="main-font-color">{{pay_buy.item.real_pay}}</span>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="付款金额" prop="current_pay">
                <Input v-model="pay_buy.item.current_pay" placeholder="请填写付款金额" type="number"></Input>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="付款时间" prop="pay_time">
                <DatePicker :value="pay_buy.item.pay_time" type="datetime"
                            format="yyyy-MM-dd HH:mm:ss"
                            placeholder="请填写付款时间(不填，默认当前时间)" style="width: 275px"
                            @on-change="getBuyPayDateTime"></DatePicker>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
          <Row :gutter="20">
            <Col span="6"></Col>
            <Col span="12">
              <FormItem label="说明" prop="remark">
                <Input v-model="pay_buy.item.pay_desc" placeholder="请填写说明"></Input>
                <div v-for="item in common.pay_desc_list" @click="setPayDesc(pay_buy.item, item.name)" class="ivu-tag ivu-tag-size-default ivu-tag-default ivu-tag-checked pointer">
                  <span class="c-515a6e">{{ item.name }}</span>
                </div>
              </FormItem>
            </Col>
            <Col span="6"></Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="closePayBuy">关闭</Button>
        <Button type="primary" :loading="pay_buy.modal_loading" @click="payBuyOperate(pay_buy.item)">确认付款</Button>
      </div>
    </Modal>

    <!--便捷添加供应商-->
    <Modal v-model="add_client.modal" title="便捷添加供应商" width="900">
      <div >
        <Form  ref="add_client" :model="add_client.item" :label-width="100" :rules="add_client.ruleInline" label-colon>
          <Row :gutter="10">
            <Col span="12">
              <FormItem label="供应商名称" prop="client_name">
                <Input v-model="add_client.item.client_name"  placeholder="请填写供应商名称"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="类型" prop="type">
                <Select v-model="add_client.item.type" placeholder="请选择类型">
                  <Option v-for="item in add_client.type_list" :value="item.id">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="closeAddClient">关闭</Button>
        <Button type="primary" :loading="add_client.modal_loading" @click="addClientFast('add_client')">保存</Button>
      </div>
    </Modal>

    <SelectGoods ref="select_goods" @selected="getSelectedGoods" :select_goods="select_goods"/>

    <!--图片预览-->
    <ImagePreview ref="image_preview"/>
  </div>
</template>

<script>
import SelectGoods from '@/components/common/SelectGoods.vue';
import ImageUpload from '@/components/common/ImageUpload.vue';
import ImagePreview from '@/components/common/Image/ImagePreview';
import ColumnsSet from "@/components/common/ColumnsSet";
import * as logistics_util from '@/utils/logistics';
import * as supplier_utils from '@/utils/supplier';
import NP, {plus} from 'number-precision';
import {createBuyOrder,apiPayBuyOperate, arriveBuyOrderAndIn, apiCheckImportGoodsValid,
  apiSetBuyPayStatusEnd,apiSetInvoicingStatusDone, apiBuyForceDeleteOperate,forceEditBuyOrderApi} from '@/api/buy/buy'
import {apiAddClientFast} from '@/api/setting/clientSetting'
import {apiGetPayDescAll} from '@/api/setting/payDesc'

import XLSX from "xlsx";
import * as clientsUtil from "@/utils/client";

export default {
  name: "BuyList",
  data() {
    return {
      force_edit: {
        modal: false,
      },
      force_operate_flag: 0,
      add_client: {
        modal: false,
        modal_loading: false,
        item: {
          id:0,
          client_name:'',
          type: 2,
        },
        ruleInline: {
          client_name: [
            {required: true, message: '请填写供应商名称'}
          ],
        },
        type_list: [
          {
            id:2,
            name: '供应商',
          },
          {
            id:3,
            name: '客户与供应商',
          },
        ],
      },
      taxList: [
        {id:1,name:'是'},
        {id:2,name:'否'},
      ],
      invoicingStatusList: [
        {id:0,name:'暂无'},
        {id:1,name:'已收票'},
        {id:2,name:'待收票'},
      ],
      import_stock: {
        import_loading: false,
        modal: false,
        model_title: '导入商品信息',
        stock_show_flag: 1,
        stock_columns: [
          {
            title: '商品名称',
            key: '商品名称',
            align: 'center',
          },
          {
            title: '商品编号',
            key: '商品编号',
            align: 'center',
          },
          {
            title: '采购数量',
            key: '采购数量',
            align: 'center',
          },
          {
            title: '采购单价',
            key: '采购单价',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
          }
        ],
        list: [],
        err_import_count: 0,
        repeat_import_count: 0,
        import_loading_progress: 0,
        import_loading_progress_doing: 0,
        import_loading_progress_total: 0,
        import_tips: '数据导入中...  导入数据时，请勿关闭弹窗、勿刷新网站',
        response_stock_err: [],
        response_stock__err_list: '',
        import_button_loading: false,
      },
      pay_buy: {
        modal:false,
        modal_loading: false,
        item: {
          id: 0,
          real_pay: 0,
          goods_pay: 0,
          current_pay: '',
          pay_time: '',
          pay_desc: '',
        }
      },
      select_goods: {
        modal: false,
        count_flag: 0,
        goods_type: 0
      },
      formItem: {
        buy_no: '',
        status: '',
        client_id: 0,
        buy_date: '',
        pay_status:0,
        include_tax:'',
      },
      checkFlag: true,
      allBuyStatus: [
        {
          "id": 1,
          "name": '待提交'
        },
        {
          "id": 4,
          "name": '已提交'
        },
      ],
      allBuyPayStatus: [
        {
          "id": 1,
          "name": '待付款'
        },
        {
          "id": 3,
          "name": '已付款'
        },
      ],
      clientFormItem: {
        invoicing_status: 0,
        to_pay:0,
        id: '',
        buy_no: '',
        part_no_postfix: '',
        supplier_id: '',
        logistics_id: 0,
        project: 0,
        logistics_order_id: '',
        logistics_pay: 0,
        include_tax: 2,
        goods_setted: [],
        flow_id: '',
        certificate:[],
        remark: '',
        buy_date: '',
        discount_amount: 0,
        goods_pay: 0,
        uploadParam: {
          list_length: 3,
          upload_type: 2,//2表示凭证上传
          module_type: 4,//4表示采购文件夹
          prefix: '',
          list: [],
          key: 0,
        },
        return_goods_set:[],
        return_cost: 0,
        real_pay:0,
        pay_desc:''
      },
      model_title: '导入采购商品',
      checkOrderModalTitle: '提交信息',
      modal1: false,
      checkOrderModal: false,
      modal_loading: {
        success: false,
        add_edit: false,
      },
      listInfo: [],
      create_info: {
        img_url: '',
      },
      list: {
        page: 1,
        total: 0,
        size: 0,
        page_size_key: 'buy_page_size_key',
      },
      ruleInline: {
        // real_pay: [
        //   {required: true, message: '请填写实付金额'}
        // ],
        supplier_id: [
          {required: true, message: '请选择供应商'},
        ],
        // address_id: [
        //   {required: true, message: '请选择收货地址'},
        // ],
      },
      ruleInlineReturn: {
        address: [
          {required: true, message: '请填写退货地址'}
        ],
      },
      file: null,
      logisticsList: [],
      supplierList: [],
      // projectList:[],
      table_columns_modal: [
        {
          title: '商品编号',
          key: 'part_no',
          width:100,
          align: 'center',
        },
        {
          title: '商品名称',
          key: 'part_name',
          slot: 'part_name',
          minWidth:260,
          align: 'left',
        },
        {
          title: '批发价',
          key: 'wholesale_price',
          slot: 'wholesale_price',
          align: 'center',
        },
        {
          title: '销售价',
          key: 'selling_price',
          slot: 'selling_price',
          align: 'center',
        },
        {
          title: '采购数量',
          key: 'buy_num',
          slot: 'buy_num',
          align: 'center',
        },
        {
          title: '单价',
          key: 'buy_price',
          slot: 'buy_price',
          align: 'center',
        },
        {
          title: '合计',
          key: 'buy_money',
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          width: 180,
          slot: 'action',
          align: 'center',
        }
      ],
      // addressList: [],

      common: {
        pay_desc_list:[],
        summary: 0,
        list_loading: false,
        view_loading: false,
        view_flag: 1,
        real_pay_columns: [
          {
            title: '流水单号',
            key: 'flow_id',
            align: 'center',
          },
          {
            title: '实付金额',
            key: 'money',
            slot: 'money',
            align: 'center',
          },
          {
            title: '付款时间',
            key: 'flow_time',
            align: 'center',
          },
          {
            title: '说明',
            key: 'desc',
            align: 'center',
          },
          {
            title: '操作人员',
            key: 'operator_name',
            align: 'center',
          },
        ]
      },
      list_more: {
        column_key: 'buy_list_columns',
        visible: false,
        user_selected_column: [],
        all_columns: [
          {
            title: '采购单号',
            key: 'buy_no',
            slot: 'buy_no',
            width: 100,
            align: 'center',
            fixed: true,
          },
          {
            title: '摘要',
            key: 'abstract',
            slot: 'abstract',
            minWidth: 120,
            align: 'left',
          },
          {
            title: '单号状态',
            key: 'status',
            slot: 'status',
            align: 'center',
          },
          {
            title: '采购数量',
            key: 'goods_num',
            align: 'center',
          },
          {
            title: '采购总额',
            key: 'goods_pay',
            align: 'center',
          },
          {
            title: '实付金额',
            key: 'real_pay',
            align: 'center',
          },
          {
            title: '付款状态',
            key: 'pay_status',
            slot: 'pay_status',
            align: 'center',
          },
          {
            title: '供应商',
            key: 'supplier_name',
            align: 'center',
          },
          {
            title: '采购时间',
            slot: 'buy_date',
            key: 'buy_date',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            slot: 'action',
            width: 150,
            fixed: true,
          }
        ],
        base_selected_column: [
          '采购单号',
          '摘要',
          '单号状态',
          '采购数量',
          '采购总额',
          '供应商',
          '采购时间',
          '操作'
        ],
        columns:[],
      },
    };
  },
  methods: {
    freeGoodsSelected(row, index) {
      row.free_flag = 1;
      row.buy_price = 0;
      this.clientFormItem.goods_setted[index].free_flag = 1;
      this.clientFormItem.goods_setted[index].buy_price = 0;

      this.priceSum(row, index)
    },
    chargeGoodsSelected(row, index) {
      row.free_flag = 0;
      this.clientFormItem.goods_setted[index].free_flag = 0;
    },
    setBuyGoodsColumn() {
      console.log(this.clientFormItem.include_tax)
      this.setTableColumnsModal(this.clientFormItem.include_tax)
    },
    setTableColumnsModal(includeTax) {
      for(let i=0; i<this.table_columns_modal.length; i++) {
          if (this.table_columns_modal[i].key == 'buy_price') {
            this.table_columns_modal[i].title = '单价';
            if (includeTax == 1) {
              this.table_columns_modal[i].title = '含税单价';
            }
          }

          if (this.table_columns_modal[i].key == 'buy_money') {
            this.table_columns_modal[i].title = '合计';
            if (includeTax == 1) {
              this.table_columns_modal[i].title = '价税合计';
            }
          }
      }
    },
    addClientFast(name) {
      this.add_client.modal_loading = true;
      this.$refs[name].validate((valid) => {
            if (valid) {
              apiAddClientFast(this.add_client.item).then(res => {
                if (res.data.err_no == 0) {
                  this.$Message.success('操作成功！');
                  this.add_client.modal = false;
                  supplier_utils.resetSupplierAll();
                  clientsUtil.resetClientsAll();
                  this.add_client.modal_loading = false;

                  this.add_client.item.id = res.data.results;
                  this.supplierList.push(this.add_client.item);
                  this.clientFormItem.supplier_id = res.data.results;
                } else {
                  this.add_client.modal_loading = false;
                  this.$Message.error(res.data.err_msg);
                }
              }).catch( error => {
                this.add_client.modal_loading = false;
              })
            } else {
              this.add_client.modal_loading = false;
            }
      });
    },
    addClients() {
      this.add_client.client_name = '';
      this.add_client.type = 2;
      this.add_client.modal = true;
    },
    closeAddClient() {
      this.add_client.client_name = '';
      this.add_client.type = 2;
      this.add_client.modal = false;
    },
    setRealPay() {
      this.clientFormItem.real_pay = this.getCreateResultMoney(this.common.summary, this.clientFormItem.discount_amount, this.clientFormItem.logistics_pay);
    },
    setPayDesc(item, name) {
      item.pay_desc = name;
    },
    getPayDescAll() {
      apiGetPayDescAll().then(res => {
        if (res.data.err_no == 0) {
          this.common.pay_desc_list = res.data.results;
        }
      });
    },
    stockUploadDelete(row, index) {
      if (row.format == 3) {
        this.import_stock.err_import_count--;
      }

      if (row.format == 2) {
        this.import_stock.repeat_import_count--;
      }

      this.import_stock.list.splice(index, 1);
    },
    importStockAction() {
      this.import_stock.import_button_loading = true;
      if (this.import_stock.list.length <= 0) {
        this.$Message.error('导入数据为空，请留意');
        return;
      }

      //判断异常和重复数据是否要忽略
      if (this.import_stock.err_import_count > 0 || this.import_stock.repeat_import_count > 0) {
        this.$Modal.confirm({
          title: "导入数据校验",
          content: '即将导入系统的数据中存在重复数据和异常数据，请否清理后再进行保存操作？异常数据和重复数据将不会存入系统！',
          okText: '确定',
          cancelText: '忽略',
          onOk: () => {
            this.import_stock.stock_show_flag = 2;
            this.import_stock.import_button_loading = false;
          },
          onCancel: () => {
            this.saveImportStock();
          }
        });
      } else {
        this.saveImportStock();
      }
    },
    saveImportStock() {
      for (let i=0; i<this.import_stock.list.length; i++) {
        if (this.import_stock.list[i].format == 1) {
          this.clientFormItem.goods_setted.push(this.import_stock.list[i].goods_info);
        }
      }

      this.import_stock.import_button_loading = false;
      this.import_stock.modal = false;
    },
    errRowClass(row, index) {
      //标红有问题的行数
      if (row.format == 2) {
        return 'warning-color';
      }

      if (row.format == 3) {
        return 'danger-color';
      }

      return '';
    },
    importClose() {
      this.import_stock.modal = false;
    },
    getFileType(filepath) {
      const reg = /.[a-zA-Z0-9]+$/;
      return reg.exec(filepath);
    },
    handleBeforeImport(file) {
      this.import_stock.import_loading = true;
      const fileType = this.getFileType(file.name).toString();

      if (!['.xlsx'].includes(fileType)) {
        this.$Message.error('导入数据格式目前仅支持 .XLSX 文件');
        this.import_stock.import_loading = false;
        return false;
      }

      const read = new FileReader();
      read.readAsBinaryString(file);
      read.onload = (event) => {
        const data = event.currentTarget.result;

        let excelData = XLSX.read(data, {type: 'binary'});
        //excelData.SheetNames[0]是获取Sheets中第一个Sheet的名字
        //excelData.Sheets[Sheet名]获取第一个Sheet的数据
        const json = XLSX.utils.sheet_to_json(excelData.Sheets[excelData.SheetNames[0]]);
        if (json) {
          let repeat_data = [];
          //数据校验
          for (let i = 0; i < json.length; i++) {
            json[i].format = 3;//异常
            if (json[i].商品名称 != undefined && json[i].商品名称 && json[i].商品编号 != undefined && json[i].商品编号 && json[i].采购数量 != undefined && json[i].采购数量 && json[i].采购单价 != undefined && json[i].采购单价) {
              json[i].format = 1;//正常

              //判断是否有重复数据
              let repeatInfo = json[i].商品名称 + json[i].商品编号;
              if (repeat_data.includes(repeatInfo)) {
                json[i].format = 2;//重复
                this.import_stock.repeat_import_count++;
              } else {
                repeat_data.push(repeatInfo);
              }
            }

            json[i].采购数量 = json[i].采购数量 ? (isNaN(parseFloat(json[i].采购数量)) ? '' : parseFloat(json[i].采购数量)) : '';
            json[i].采购单价 = json[i].采购单价 ? (isNaN(parseFloat(json[i].采购单价)) ? '' : parseFloat(json[i].采购单价)) : '';

            // if (json[i].format == 3) {
            //   this.import_stock.err_import_count++;
            // }
          }
        } else {
          this.$Message.error('文件上传有误，请核对文件格式');
        }

        //验证导入数据是否合规
        let param = {
          import_data: json
        }
        apiCheckImportGoodsValid(param).then(res => {
          if (res.data.err_no == 0) {
            for (let i=0; i<res.data.results.length; i++) {
              if (res.data.results[i].format == 3) {
                this.import_stock.err_import_count++;
              }
            }
            this.import_stock.list = res.data.results;
            this.import_stock.stock_show_flag = 2;
            this.import_stock.import_loading = false;
          }
        })
      };

      return false;
    },
    importProduct() {
      this.import_stock.stock_show_flag = 1;
      this.import_stock.err_import_count = 0;
      this.import_stock.repeat_import_count = 0;
      this.import_stock.list = [];
      //导入产品数据
      this.import_stock.modal = true;
    },
    getGoodsType(type) {
      let name = '';
      switch (type) {
        case 1:
          name = '物料';
          break;
        case 2:
          name = '成品';
          break;
        case 3:
          name = '半成品';
          break;
        case 4:
          name = '虚拟';
          break;
      }
      return name;
    },
    selectTap(flag) {
      this.common.view_flag = flag;
    },
    closePayBuy() {
      this.pay_buy.modal = false;
    },
    payBuyOperate(row) {
      this.pay_buy.modal_loading = true;
      if (row.current_pay ==0 || row.current_pay<0) {
        this.pay_buy.modal_loading = false;
        this.$Message.error('付款金额不能为0或负数！');
        return false;
      }

      this.$Modal.confirm({
        title: "付款确认提示",
        content: '付款操作即将执行，请确认填写无误？当付款金额加上已付金额大于等于订单金额时，订单将自动完结！',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            link_id : row.id,
            pay_money: row.current_pay,
            pay_time: row.pay_time,
            remark: row.pay_desc,
          }
          apiPayBuyOperate(param).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              this.pay_buy.modal = false;
              this.getBuyOrderById(row.id);
              this.getOrderList();
              this.pay_buy.modal_loading = false;
            } else {
              this.pay_buy.modal_loading = false;
              this.$Message.error(res.data.err_msg);
            }
          }).catch( error => {
            this.pay_buy.modal_loading = false;
          })
        },
        onCancel: () => {
          this.pay_buy.modal_loading = false;
        }
      });

    },
    getBuyPayDateTime(value, type) {
      this.pay_buy.item.pay_time = value;
    },
    payBuyOrder(row) {
      this.pay_buy.item.id = row.id;
      this.pay_buy.item.goods_pay = row.goods_pay;
      this.pay_buy.item.real_pay = row.real_pay;
      this.pay_buy.item.current_pay = NP.minus(row.goods_pay, row.real_pay);
      this.pay_buy.item.remark = '';
      this.pay_buy.item.pay_time = '';
      this.pay_buy.modal = true;
    },
    getCreateResultMoney($front, $back, logistics_pay) {
      return NP.minus(NP.plus($front, logistics_pay), $back);
    },
    pageSizeChange(page_size) {
      this.list.size = page_size;
      localStorage.setItem(this.list.page_size_key, page_size);
      this.getOrderList();
    },
    getFileList(val) {
      this.clientFormItem.certificate = [];
      for (let i = 0; i < val.length; i++) {
        this.clientFormItem.certificate[i] = {};
        this.clientFormItem.certificate[i].url = val[i].url;
      }
    },
    //查看图片
    imgShow(value, img_info) {
      this.$refs.image_preview.imgPreview(value, img_info);
    },
    getStatusColorTg(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'background-color:#ed4014';
          break;
        case 2:
          rs = 'background-color:#19be6b';
          break;
        case 3:
          rs = 'background-color:#2db7f5';
          break;
        case 4:
        case 8:
          rs = 'background-color:#2775ff';
          break;
        case 5:
          rs = 'background-color:#c5c8ce';
          break;
        case 6:
        case 7:
          rs = 'background-color:#ff9900';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getStatusColor(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = 'color:#ed4014';
          break;
        case 2:
          rs = 'color:#19be6b';
          break;
        case 3:
          rs = 'color:#2db7f5';
          break;
        case 4:
        case 8:
          rs = 'color:#2775ff';
          break;
        case 5:
          rs = 'color:#c5c8ce';
          break;
        case 6:
        case 7:
          rs = 'color:#ff9900';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getPayStatusColor(index) {
      let rs = '';
      switch (index) {
        // case 2:
        //   rs = 'color:#ff9900';
        //   break;
        case 3:
        case 4:
          rs = 'color:#2775ff';
          break;
        case 1:
        case 2:
          rs = 'color:#ed4014';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getPayStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待付款';
          break;
        case 2:
          rs = '待付款';
          break;
        case 3:
          rs = '已付款';
          break;
        case 4:
          rs = '已付款';
          break;
        case 5:
          rs = '无需付款';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    setPayStatusFinish(item, show_type='view') {
      if (item.pay_status != 2 || [1,5].includes(item.status)) {
        return;
      }
      this.$Modal.confirm({
        title: "订单付款金额状态完结操作",
        content: '采购金额为: <strong class="status-main">'+item.goods_pay.toString()
            +'</strong><br/> 实付金额为: <strong class="status-main">'+item.real_pay.toString()+'</strong><br/>'
            + '确定将订单状态设置为<span class="status-danger">已付款</span>状态？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          let param = {
            main_no: item.buy_no,
            buy_id: item.id
          }
          apiSetBuyPayStatusEnd(param).then(res => {
            if (res.data.err_no == 0) {
              this.$Message.success(res.data.results);
              if (show_type == 'view') {
                this.checkOrderInfo(item);
              }
              this.getOrderList();
            } else {
              this.$Message.error(res.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    getStatusText(index) {
      let rs = '';
      switch (index) {
        case 1:
          rs = '待提交';
          break;
        case 2:
          rs = '待收货';
          break;
        case 3:
          rs = '待入库';
          break;
        case 4:
        case 8:
          rs = '已提交';
          break;
        case 5:
          rs = '已取消';
          break;
        case 6:
          rs = '已退货';
          break;
        case 7:
          rs = '有退货';
          break;
        default:
          rs = '';
          break;
      }

      return rs;
    },
    getPayTypeColor(index) {
      //获取不同配色
      return index == 2 ? 'color:#ff9900' : '';
    },
    priceSum(row, index) {
      //求总价
      if (isNaN(row.buy_num) || isNaN(row.buy_price)) {
        this.clientFormItem.goods_setted[index].buy_money = 0;
      } else {
        //赋值
        this.clientFormItem.goods_setted[index].buy_num = row.buy_num;
        this.clientFormItem.goods_setted[index].buy_price = row.buy_price;
        row.buy_money = NP.times(row.buy_num, row.buy_price);
        this.clientFormItem.goods_setted[index].buy_money = row.buy_money;
      }

      if (!isNaN(row.selling_price) ) {
        this.clientFormItem.goods_setted[index].selling_price = row.selling_price;
      }

      if (!isNaN(row.wholesale_price)) {
        //赋值
        this.clientFormItem.goods_setted[index].wholesale_price = row.wholesale_price;
      }

    },
    addGoodsHandleSummary({columns, data}) {
      //表格数据汇总
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: '总计'
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value)) && (index == 4 || index == 6)) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return NP.plus(prev, curr);
            } else {
              return prev;
            }
          }, 0);

          if (index == 4) {
            sums[key] = {
              key,
              value: '共 ' + v
            };
          } else if (index == 6) {
            sums[key] = {
              key,
              value: '共 ' + v
            };
            this.common.summary = v;//临时记录计算总价
          }

        } else {
          sums[key] = {
            key,
            value: ''
          };
        }
      });

      return sums;
    },

    deleteGoodsSelected(row, index) {
      this.clientFormItem.goods_setted.splice(index, 1);
    },

    addGoods() {//添加物料
      this.$refs.select_goods.select_goods.goods_type = this.select_goods.goods_type;
      this.$refs.select_goods.showGoodsList();
    },
    //获取选中的商品
    getSelectedGoods(val) {
      //设置选中的物料
      let selected_goods = val;
      let setted_goods = this.clientFormItem.goods_setted;

      if (selected_goods.length) {
        for (let i = 0, len = selected_goods.length; i < len; i++) {
          let added_flag = false;

          //排除已添加的
          for (let j = 0, jlen = setted_goods.length; j < jlen; j++) {
            if (setted_goods[j].id == selected_goods[i].id) {
              added_flag = true;
              break;
            }
          }

          //如果未添加
          if (!added_flag) {
            selected_goods[i].buy_money = 0;
            selected_goods[i].buy_num = '';
            selected_goods[i].goods_type = selected_goods[i].type;
            selected_goods[i].buy_price = 0;
            selected_goods[i].free_flag = 0;
            selected_goods[i].selling_price = 0;
            selected_goods[i].wholesale_price = 0;
            setted_goods.push(selected_goods[i]);
          }
        }
      }
    },
    handleReset(name) {
      //弹出框重置
      this.modal1 = false;
      this.modal_loading.add_edit = false;
    },
    create(name) {
      //创建物料信息
      this.model_title = '创建采购单信息';
      this.modal1 = true;
      this.modal_loading.add_edit = false;

      this.$refs[name].resetFields();
      this.clientFormItem.part_no_postfix = '';
      this.clientFormItem.id = '';
      this.clientFormItem.supplier_id = '';
      this.clientFormItem.buy_date = '';

      this.clientFormItem.uploadParam.list = [];
      this.clientFormItem.uploadParam.key += 1;
      this.common.summary = 0;
      this.clientFormItem.logistics_pay = 0;
      this.clientFormItem.discount_amount = 0;

      this.$axios.get('/api/buy/getBuyId').then((response) => {
        if (response.data.err_no == 0) {
          this.clientFormItem.buy_no = response.data.results;
          this.clientFormItem.goods_setted = [];

          this.clientFormItem.uploadParam.prefix = this.clientFormItem.buy_no;
          this.common.view_loading = false;
        }
      });
    },
    listEdit(row) {
      this.getBuyOrderById(row.id);
      this.model_title = '修改采购订单信息';
      this.modal1 = true;
      this.modal_loading.add_edit = false;
    },

    //获取采购单信息
    getBuyOrderById(id) {
      this.common.view_loading = true;
      this.$axios.get('/api/buy/getBuyOrderById?id=' + id).then((res) => {
        this.common.view_loading = false;
        let result = res.data.results;
        if (res.data.err_no == 0) {
          this.clientFormItem.id = result.id;
          this.clientFormItem.buy_no = result.buy_no;
          this.clientFormItem.status = result.status;
          this.clientFormItem.goods_num = result.goods_num;
          this.clientFormItem.goods_pay = parseFloat(result.goods_pay);
          this.clientFormItem.real_pay = parseFloat(result.real_pay);
          this.clientFormItem.to_pay = NP.minus(this.clientFormItem.goods_pay, this.clientFormItem.real_pay)
          this.clientFormItem.pay_status = parseFloat(result.pay_status);
          this.clientFormItem.pay_desc = result.pay_desc;
          this.clientFormItem.part_no_postfix = result.part_no_postfix;
          this.clientFormItem.supplier_id = result.supplier_id;
          this.clientFormItem.project = result.project;
          this.clientFormItem.logistics_id = result.logistics_id;
          this.clientFormItem.logistics_pay = parseFloat(result.logistics_pay);
          this.clientFormItem.logistics_order_id = result.logistics_order_id;
          this.clientFormItem.include_tax = result.include_tax;
          this.setTableColumnsModal(result.include_tax);
          this.clientFormItem.invoicing_status = result.invoicing_status;
          this.clientFormItem.flow_id = result.flow_id;
          this.clientFormItem.certificate = result.certificate == '[]' ? []: result.certificate;

          this.clientFormItem.remark = res.data.results.remark;
          this.clientFormItem.buy_date = result.buy_date;
          this.clientFormItem.discount_amount = parseFloat(result.discount_amount);

          for(let i=0; i<result.goods_setted.length; i++) {
            result.goods_setted[i].buy_num = parseFloat(result.goods_setted[i].buy_num);
            result.goods_setted[i].buy_money = parseFloat(result.goods_setted[i].buy_money);
            result.goods_setted[i].selling_price = parseFloat(result.goods_setted[i].selling_price);
            result.goods_setted[i].wholesale_price = parseFloat(result.goods_setted[i].wholesale_price);
            result.goods_setted[i].stock_all = parseFloat(result.goods_setted[i].stock_all);
          }
          this.clientFormItem.goods_setted = result.goods_setted;

          this.clientFormItem.return_goods_set = [];
          this.clientFormItem.return_cost = 0;
          if (result.return_goods_set !== undefined) {
            this.clientFormItem.return_goods_set = result.return_goods_set;
            this.clientFormItem.return_cost = parseFloat(result.return_cost);
          }

          for (let i=0; i<result.flow_info.length; i++) {
            result.flow_info[i].money = parseFloat(result.flow_info[i].money);
          }
          this.clientFormItem.flow_info = result.flow_info

          this.clientFormItem.uploadParam.prefix = this.clientFormItem.buy_no;
          this.clientFormItem.uploadParam.list = JSON.parse(JSON.stringify(this.clientFormItem.certificate));
          this.clientFormItem.uploadParam.key += 1;
        }
      });
    },

    //删除操作提示
    listDeleteAlert(row) {
      this.$Modal.confirm({
        title: "删除采购订单信息",
        content: '确定删除采购订单信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.deleteListInfo(row.id);
        },
        onCancel: () => {
        }
      });
    },
    forceDelete(row) {
      this.$Modal.confirm({
        title: "强制删除采购订单信息",
        content: '确定强制删除采购订单信息？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.forceDeleteOperate(row.id);
        },
        onCancel: () => {
        }
      });
    },
    forceEdit(row) {
      this.getBuyOrderById(row.id);
      this.force_edit.modal = true;
    },
    forceDeleteOperate(id) {
      apiBuyForceDeleteOperate({buy_id: id}).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.getOrderList();
        } else {
          this.$Message.error(res.data.err_msg);
        }
      })
    },

    //取消订单
    cancelBuyOrder(row) {
      this.$Modal.confirm({
        title: "取消采购订单信息",
        content: '确定取消采购订单信息？一旦取消订单，该订单将失效，不再有效。',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.$axios.post('/api/buy/cancelBuyOrder', {id: row.id}).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getOrderList();
            } else {
              this.$Message.error(response.data.err_msg);
            }
          });
        },
        onCancel: () => {
        }
      });
    },

    //删除列表信息
    deleteListInfo($id) {
      this.$axios.post('/api/buy/deleteListInfo', {id: $id})
        .then((response) => {
          if (response.data.err_no == 0) {
            this.$Message.success(response.data.results);
            this.getOrderList();
          } else {
            this.$Message.error(response.data.err_msg);
          }
        });
    },

    forceEditBuyOrder(name) {
      this.modal_loading.add_edit = true;

      let param = {}; //创建form对象
      param.buy_no = this.clientFormItem.part_no_postfix == '' ? this.clientFormItem.buy_no : this.clientFormItem.buy_no.concat('-', this.clientFormItem.part_no_postfix);
      param.certificate = this.clientFormItem.certificate;
      param.id = this.clientFormItem.id;
      param.logistics_id = this.clientFormItem.logistics_id;
      param.logistics_order_id = this.clientFormItem.logistics_order_id;
      param.include_tax = this.clientFormItem.include_tax;
      param.invoicing_status = this.clientFormItem.invoicing_status;
      param.remark = this.clientFormItem.remark;
      param.goods_setted = this.clientFormItem.goods_setted;

      forceEditBuyOrderApi(param).then((res) => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.force_edit.modal = false;
          this.getOrderList();
          //注意：加载按钮不能在这里关闭，会造成重复提交
          // this.modal_loading.add_edit = false;
        } else {
          this.$Message.error(res.data.err_msg);
          this.modal_loading.add_edit = false;
        }
      }).catch((error) => {
        this.$Message.error('保存失败');
        this.modal_loading.add_edit = false;
      });

    },

    //创建采购订单
    createBuyOrder(name) {
      this.modal_loading.add_edit = true;

      this.$refs[name].validate((valid) => {
        if (valid) {
          //判断是否添加了采购物料
          let goods_setted = [];
          if (this.clientFormItem.goods_setted.length < 1) {
            this.$Message.error('请添加采购商品');
            this.modal_loading.add_edit = false;
            return;
          }

          //过滤无效数据
          for (let i = 0; i < this.clientFormItem.goods_setted.length; i++) {
            let tmp = {
              goods_id: '',
              buy_num: 0,
              buy_price: 0,
            };
            tmp.goods_id = this.clientFormItem.goods_setted[i].id;
            tmp.buy_num = this.clientFormItem.goods_setted[i].buy_num;
            tmp.buy_price = this.clientFormItem.goods_setted[i].buy_price;
            tmp.free_flag = this.clientFormItem.goods_setted[i].free_flag;

            if (tmp.buy_num <= 0) {
              this.$Message.error('采购商品数量不能小于等于0');
              this.modal_loading.add_edit = false;
              return;
            }

            if (tmp.buy_price <= 0 && tmp.free_flag != 1) {
              this.$Message.error('采购商品单价不能小于等于0');
              this.modal_loading.add_edit = false;
              return;
            }

            goods_setted[i] = tmp;
          }


          //优惠额度确认
          if (this.clientFormItem.discount_amount < 0) {
            //自付时，运费应不为0
            this.$Message.error('优惠不能小于0！');
            this.modal_loading.add_edit = false;
            return;
          }

          //实付金额确认
          if (this.clientFormItem.real_pay < 0) {
            this.$Message.error('实付金额不能小于0！');
            this.modal_loading.add_edit = false;
            return;
          }

          let param = new FormData(); //创建form对象
          if (typeof this.clientFormItem.certificate === Object) {
            param.append('certificate', this.clientFormItem.certificate, this.clientFormItem.certificate.name);//通过append向form对象添加数据
          } else {
            param.append('certificate', this.clientFormItem.certificate);//通过append向form对象添加数据
          }

          //获取供应商名称
          for (let i=0; i<this.supplierList.length; i++) {
            if (this.clientFormItem.supplier_id == this.supplierList[i].id) {
              param.append('supplier_name', this.supplierList[i].client_name);
            }
          }


          let buy_no = this.clientFormItem.part_no_postfix == '' ? this.clientFormItem.buy_no : this.clientFormItem.buy_no.concat('-', this.clientFormItem.part_no_postfix);

          param.append('id', this.clientFormItem.id);
          param.append('buy_no', buy_no);
          param.append('real_pay', this.clientFormItem.real_pay);
          param.append('pay_desc', this.clientFormItem.pay_desc);
          param.append('supplier_id', this.clientFormItem.supplier_id);
          param.append('logistics_id', this.clientFormItem.logistics_id);
          param.append('logistics_order_id', this.clientFormItem.logistics_order_id);
          param.append('logistics_pay', this.clientFormItem.logistics_pay);
          param.append('project', this.clientFormItem.project !== undefined ? this.clientFormItem.project : 0);
          param.append('include_tax', this.clientFormItem.include_tax);
          param.append('invoicing_status', this.clientFormItem.invoicing_status);
          param.append('remark', this.clientFormItem.remark);
          param.append('discount_amount', this.clientFormItem.discount_amount);
          param.append('buy_date', this.clientFormItem.buy_date);
          param.append('goods_setted', JSON.stringify(this.clientFormItem.goods_setted));
          param.append('certificate', JSON.stringify(this.clientFormItem.certificate));

          createBuyOrder(param).then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.modal1 = false;
              this.getOrderList();
              //注意：加载按钮不能在这里关闭，会造成重复提交
              // this.modal_loading.add_edit = false;
            } else {
              this.$Message.error(response.data.err_msg);
              this.modal_loading.add_edit = false;
            }
          }).catch((error) => {
            this.$Message.error('保存失败');
            this.modal_loading.add_edit = false;
          });
        } else {
          this.modal_loading.add_edit = false;
        }
      });
    },
    searchSubmit() {
      this.list.page = 1;
      this.getOrderList();
    },
    cancelSearch(name) {
      this.$refs[name].resetFields();
      this.list.page = 1;
      this.getOrderList();
    },
    getOrderList() {
      this.common.list_loading = true;
      let param = {
        page: this.list.page,
        page_size: this.list.size,
        buy_no: this.formItem.buy_no,
        status: this.formItem.status,
        buy_date: this.formItem.buy_date,
        client_id: this.formItem.client_id,
        pay_status: this.formItem.pay_status,
        include_tax: this.formItem.include_tax,
      };
      this.$axios.post('/api/buy/getOrderList', param).then((res) => {
        this.common.list_loading = false;
        if (res.data.err_no == 0) {
          for (let i=0; i<res.data.results.list.length; i++) {
            res.data.results.list[i].goods_num = parseFloat(res.data.results.list[i].goods_num);
            res.data.results.list[i].goods_pay = parseFloat(res.data.results.list[i].goods_pay);
            res.data.results.list[i].real_pay = parseFloat(res.data.results.list[i].real_pay);
            res.data.results.list[i].discount_amount = parseFloat(res.data.results.list[i].discount_amount);
          }
          this.listInfo = res.data.results.list;
          this.list.total = res.data.results.total;
          this.list.size = res.data.results.size;
        }
      });
    },
    changePage(page) {
      this.list.page = page;
      this.getOrderList();
    },
    getDateTime(value, type) {
      this.clientFormItem.buy_date = value;
    },

    //获取供应商列表
    getSupplierList() {
      supplier_utils.getSupplierAll().then(res => {
        this.supplierList = res;
      });
    },

    //打开审核页面
    checkOrderInfo(row) {
      //待审核状态时，显示标题为 审核订单信息
      if (row.status != 1) {
        //非审核状态时,标题为 查看订单信息
        this.checkOrderModalTitle = '查看订单信息';
      }

      this.getBuyOrderById(row.id);

      this.checkOrderModal = true;
      //加载按钮关闭
      this.modal_loading.success = false;
    },

    //显示订单信息
    showEditBuyOrder(clientFormItem) {
      this.listEdit(clientFormItem);
      this.checkOrderModal = false;
    },
    //采购订单审核通过
    passBuyOrder(clientFormItem) {
      this.modal_loading.success = true;

      this.$axios.post('/api/buy/passBuyOrder', {id: clientFormItem.id}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getOrderList();
          this.checkOrderModal = false;
          //加载按钮不在这里关闭
        } else {
          this.$Message.error(response.data.err_msg);
          this.modal_loading.success = false;
        }
      })
      .catch((error) => {
        this.$Message.error('操作失败');
        this.modal_loading.success = false;
      });
    },
    setInvoicingStatus(clientFormItem) {
      this.modal_loading.success = true;
      let param = {
        id: clientFormItem.id,
        main_no: clientFormItem.buy_no
      }
      apiSetInvoicingStatusDone(param).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.getOrderList();
          this.checkOrderModal = false;
          //加载按钮不在这里关闭
        } else {
          this.$Message.error(res.data.err_msg);
          this.modal_loading.success = false;
        }
      }).catch((error) => {
        this.$Message.error('操作失败');
        this.modal_loading.success = false;
      });
    },
    arriveBuyOrderAndIn(clientFormItem) {
      //到货并直接入库，无需退货
      let param = {
        id: clientFormItem.id,
      };
      arriveBuyOrderAndIn(param).then(res => {
        if (res.data.err_no == 0) {
          this.$Message.success(res.data.results);
          this.getOrderList();
          this.checkOrderModal = false;
        } else {
          this.$Message.error(res.data.err_msg);
          this.modal_loading.success = false;
        }
      }).catch((res) => {
        this.modal_loading.success = false;
      });
    },
    //订单已经到货
    arriveBuyOrder(clientFormItem) {
      this.modal_loading.success = true;

      let param = {
        id: clientFormItem.id,
      };

      this.$axios.post('/api/buy/arriveBuyOrder', param)
          .then((response) => {
            if (response.data.err_no == 0) {
              this.$Message.success(response.data.results);
              this.getOrderList();
              this.checkOrderModal = false;
            } else {
              this.$Message.error(response.data.err_msg);
              this.modal_loading.success = false;
            }
          }).catch((res) => {
            this.modal_loading.success = false;
          });
    },

    //采购单入库
    buyOrderIn(clientFormItem) {
      this.modal_loading.success = true;

      this.$axios.post('/api/goods/buyOrderIn', {buy_id: clientFormItem.id}).then((response) => {
        if (response.data.err_no == 0) {
          this.$Message.success(response.data.results);
          this.getOrderList();
          this.checkOrderModal = false;
        } else {
          this.$Message.error(response.data.err_msg);
          this.modal_loading.success = false;
        }
      });
    },

    //审核弹出框重置
    handleResetOrderModal(name) {
      this.checkOrderModal = false;
      name.modal = false;
    },

    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.formItem.buy_date = dateRange;
      } else {
        this.formItem.buy_date = "";
      }

      this.searchSubmit();
    },
    setSelectedPageSize() {
      let size = localStorage.getItem(this.list.page_size_key);
      this.list.size = size ? (size > 50 ? 40 : parseInt(size)) : 0;
    },
    getLogisticsList() {
      logistics_util.getLogisticsAll().then(res => {
        this.logisticsList = res;
      });
    },
    // getProjectList() {
    //   project_util.getProjectAll().then(res => {
    //     this.projectList = res;
    //   });
    // },
  },
  mounted() {
    this.$refs.columns_set.getUserColumns();

    this.setSelectedPageSize();
    this.getOrderList();

    this.getLogisticsList();
    // this.getProjectList();
    this.getSupplierList();
    // this.getAddressList();
    this.getPayDescAll();
  },
  created() {
    this.aCheck.plateName = 'buy_manage';
    if (this.aCheck.rightCheck('force_procurement_list')) {
      this.force_operate_flag = 1;
    }
  },
  components: {
    SelectGoods,
    ImageUpload,
    ImagePreview,
    ColumnsSet
  }
};
</script>

<style scoped>
.select-flag {
  font-size: 16px;
  font-weight: 500;
}

#buy_list {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.modalForm {
  width: 100%;
  min-height: 400px;
}

.client_create {
  position: absolute;
  right: 0;
  top: -8px;
}

#buy_list .ivu-btn-small {
  font-size: 12px !important;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#list {
  margin-top: 12px;
  margin-bottom: 40px;
  position: relative;
}

#list .page {
  margin-top: 16px;
}

.client_import_template {
  position: absolute;
  left: 140px;
  top: 10px;
}

</style>
<style>
#buy_list .ivu-card {
  background-color: #fafafa;
}
#buy_list #filter-bar .ivu-card-body {
  padding: 6px 16px 16px 16px;
}

#buy_list .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#buy_list .ivu-form-item {
  margin-bottom: 0;
}

#list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#list .ivu-page {
  float: right;
}

#list .ivu-spin-fix {
  top: 40px !important;
  height:calc(100% - 40px) !important;
}

.buy_list .ivu-table-overflowX, .buy_list .ivu-table-tip {
  overflow-x: hidden;
}

.buy_list .ivu-table-wrapper {
  overflow: visible;
}

#filter-bar .ivu-tag-checked .ivu-tag-text {
  color: #515a6e !important;
}

#filter-bar .ivu-form-item {
  margin-right: 8px;
  margin-bottom: 0;
  margin-top: 10px;
}
</style>
